import { Outlet } from "react-router-dom";

const Layout = () => {
  return (
    <main className="App">
      {/* Everything nested inside App is represented by <Outlet />. */}
      <Outlet />
    </main>
  );
};

export default Layout;
