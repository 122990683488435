// src/App.js
import Register from "./pages/auth/Register";
import Login from "./pages/auth/Login";
import Home from "./pages/Home";
import Layout from "./structure/Layout";
import Missing from "./pages/auth/Missing";
import RequireAuth from "./utils/RequireAuth";
import PersistLogin from "./utils/PersistLogin";
import UserTop from "./pages/user/UserTop";
import HskList from "./pages/list/HskList";
import { Routes, Route } from "react-router-dom";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* public routes */}
        <Route path="/" element={<Home />} />
        <Route path="login" element={<Login />} />
        <Route path="register" element={<Register />} />

        {/* we want to protect these routes */}
        <Route element={<PersistLogin />}>
          <Route element={<RequireAuth />}>
            <Route path="/user" element={<UserTop />} />
            <Route path="/list/hsk" element={<HskList />} />
          </Route>
        </Route>
        {/* catch all */}
        <Route path="*" element={<Missing />} />
      </Route>
    </Routes>
  );
}
export default App;
