import React from "react";
import { useNavigate, Link } from "react-router-dom";
import useLogout from "../../hooks/useLogout";
import { Typography, Box, Container, Button } from "@mui/material";

const UserTop = () => {
  const navigate = useNavigate();
  const logout = useLogout();

  const signOut = async () => {
    await logout();
    navigate("/login", { replace: true });
  };
  return (
    <Container maxWidth="md">
      <Box sx={{ textAlign: "center", mt: 5 }}>
        <Typography variant="h3" component="h1" gutterBottom>
          User Details
        </Typography>
        <Typography variant="body1" component="p">
          This is the main page that users will see after logging in. It will contain their usage details, streak, etc.
          {/* You are logged in as {auth.email} */}
        </Typography>
      </Box>
      <section>
        <h1>Links</h1>
        <br />
        <h2>Public</h2>
        <Link to="/login">Login</Link>
        <br />
        <Link to="/register">Register</Link>
        <br />
        <br />
        <h2>Private</h2>
        <Link to="/">Home</Link>
        <br />
        <Link to="/editor">Editors Page</Link>
        <br />
        <Link to="/admin">Admin Page</Link>
        <br />
        <Link to="/list/hsk">HSK List Page</Link>
        <br />
      </section>
      <Button onClick={signOut} variant="contained" color="error">
        Sign Out
      </Button>
    </Container>
  );
};

export default UserTop;
