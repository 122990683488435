import axios from "../utils/axios";
import useAuth from "./useAuth";

const useRefreshToken = () => {
  const { setAuth } = useAuth();

  const refresh = async () => {
    try {
      console.log("Attempting refresh token...");
      const response = await axios.post(
        "/refreshToken", // The server will get the refresh token from the cookie
        {},
        { withCredentials: true } // Ensure cookies are sent
      );
      setAuth((prev) => {
        console.log("Previous Auth:", JSON.stringify(prev));
        console.log("New AccessToken:", response.data.accessToken);
        return {
          ...prev,
          accessToken: response.data.accessToken,
        };
      });
      return response.data.accessToken;
    } catch (err) {
      console.error("useRefreshToken: ", err);
      throw err;
    }
  };
  return refresh;
};

export default useRefreshToken;
