import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { TextField, Button, Typography, Box, Tooltip, Alert, Checkbox, FormControlLabel } from "@mui/material";
import { CheckCircle, Cancel } from "@mui/icons-material";
import useAuth from "../../hooks/useAuth";
import axios from "../../utils/axios";

const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{6,24}$/;

const Login = () => {
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";
  const [errorMessage, setErrorMessage] = useState(null);
  const [saveEmail, setSaveEmail] = useState(() => JSON.parse(localStorage.getItem("saveEmail")) || true);

  const { handleSubmit, control, watch } = useForm({
    defaultValues: {
      email: saveEmail ? localStorage.getItem("savedEmail") || "" : "",
      password: "",
    },
    mode: "onChange",
  });

  const onSubmit = async (data) => {
    const { email, password } = data;
    try {
      const response = await axios.post(
        "/userAuth",
        { email, password },
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true, // Include cookies in the request
        }
      );
      console.log(JSON.stringify(response?.data));
      const { accessToken } = response.data;
      // console.log("Access Token:", accessToken);
      setAuth({ email, accessToken });
      if (saveEmail) {
        localStorage.setItem("savedEmail", email);
      } else {
        localStorage.removeItem("savedEmail");
      }
      setErrorMessage(null); // Clear error on successful login
      // navigate(from, { replace: true });
      navigate("/user", { replace: true });
    } catch (error) {
      if (!error?.response) {
        setErrorMessage("The server is not responding. Please try again later.");
      } else if (error?.response?.status === 401) {
        console.log("error.data: " + JSON.stringify(error.response.data.message));
        setErrorMessage(error.response.data.message || "Invalid email or password.");
      } else {
        setErrorMessage("An error occurred.");
      }
    }
  };

  const toggleSaveEmail = () => {
    setSaveEmail((prev) => {
      const newValue = !prev;
      localStorage.setItem("saveEmail", JSON.stringify(newValue));
      return newValue;
    });
  };

  const email = watch("email");
  const isEmailValid = EMAIL_REGEX.test(email);

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        maxWidth: 400,
        mx: "auto",
        mt: 5,
        display: "flex",
        flexDirection: "column",
        gap: 2,
        alignItems: "center",
      }}
    >
      <Typography variant="h4" component="h1" gutterBottom>
        Login
      </Typography>
      {errorMessage && (
        <Alert severity="error" sx={{ width: "100%" }}>
          {errorMessage}
        </Alert>
      )}
      {/* Email Address Field */}
      <Controller
        name="email"
        control={control}
        rules={{
          required: "Email address is required",
          pattern: {
            value: EMAIL_REGEX,
            message: "Invalid email address.",
          },
        }}
        render={({ field, fieldState }) => (
          <Tooltip title="Enter a valid email address." placement="top" arrow>
            <TextField
              {...field}
              label="Email Address"
              variant="outlined"
              fullWidth
              error={!!fieldState.error}
              helperText={fieldState.error ? fieldState.error.message : ""}
              InputProps={{
                endAdornment: isEmailValid ? <CheckCircle color="success" /> : <Cancel color="error" />,
              }}
            />
          </Tooltip>
        )}
      />
      {/* Password Field */}
      <Controller
        name="password"
        control={control}
        rules={{
          required: "Password is required",
          pattern: {
            value: PASSWORD_REGEX,
            message: "6-24 characters. Must include uppercase, lowercase, and a number.",
          },
        }}
        render={({ field, fieldState }) => (
          <Tooltip title="6-24 characters. Must include uppercase, lowercase, and a number." placement="top" arrow>
            <TextField
              {...field}
              label="Password"
              type="password"
              variant="outlined"
              fullWidth
              error={!!fieldState.error}
              helperText={fieldState.error ? fieldState.error.message : ""}
            />
          </Tooltip>
        )}
      />
      <FormControlLabel
        control={<Checkbox checked={saveEmail} onChange={toggleSaveEmail} />}
        label="Save Email Address in Login Form"
      />
      {/* Submit Button */}
      <Button type="submit" variant="contained" color="primary" fullWidth>
        Log In
      </Button>
    </Box>
  );
};

export default Login;
