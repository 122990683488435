import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useNavigate, Link } from "react-router-dom";
import { TextField, Button, Typography, Box, Tooltip, Alert } from "@mui/material";
import { CheckCircle, Cancel } from "@mui/icons-material";
import axios from "../../utils/axios";

const NAME_REGEX = /^[^@]{1,24}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{6,24}$/;
const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const Register = () => {
  const [loading, setLoading] = useState(true);
  const [text, setText] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [userIp, setUserIp] = useState("");
  const [userAgent, setUserAgent] = useState("");
  const navigate = useNavigate();
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    const fetchText = async () => {
      try {
        const response = await axios.get("/getText", {
          params: {
            text: "register01",
          },
        });
        console.log("response", response);
        setText(response.data[0].text_en);
        axios
          .get("https://api.ipify.org?format=json")
          .then((response) => {
            const ip = response.data.ip;
            setUserIp(ip);
          })
          .catch((error) => console.error("IPify Error:", error));
        setUserAgent(navigator.userAgent);
      } catch (error) {
        console.error("Error retrieving data:", error);
      }
    };
    const fetchData = async () => {
      setLoading(true);
      await Promise.all([fetchText()]);
      setLoading(false);
    };
    fetchData();
  }, []);

  const pwd = watch("password");

  const onSubmit = async (data) => {
    console.log("Form Data:", data);
    const postData = { ...data, userIp, userAgent };
    try {
      const response = await axios.post("/userRegister", postData);
      if (response.status === 201) {
        navigate("/user");
      }
    } catch (error) {
      if (!error?.response) {
        console.error("No response from server: ", error);
        setErrorMessage("No response from server.");
      } else if (error.status === 409) {
        console.log("error.data: " + JSON.stringify(error.data));
        setErrorMessage(error.data.message || "This user already exists.");
      } else {
        console.error("Error retrieving instructions:", error);
        setErrorMessage(error || "An error occurred.");
      }
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      sx={{
        maxWidth: 400,
        mx: "auto",
        mt: 5,
        display: "flex",
        flexDirection: "column",
        gap: 2,
        alignItems: "center",
      }}
    >
      <Typography variant="h4" component="h1" gutterBottom>
        Register
      </Typography>

      {loading ? <Typography variant="body1">Loading...</Typography> : <Typography variant="body1">{text}</Typography>}
      {errorMessage && (
        <Alert severity="error" sx={{ width: "100%" }}>
          {errorMessage}
        </Alert>
      )}
      {/* First Name Field */}
      <Controller
        name="firstname"
        control={control}
        defaultValue=""
        rules={{
          required: "First name is required",
          maxLength: {
            value: NAME_REGEX,
            message: "First name cannot exceed 24 characters.",
          },
        }}
        render={({ field }) => (
          <Tooltip title="First name must be 1-24 characters." placement="top" arrow>
            <TextField
              {...field}
              label="First Name"
              variant="outlined"
              fullWidth
              error={!!errors.firstname}
              helperText={errors.firstname ? errors.firstname.message : ""}
              InputProps={{
                endAdornment: errors.firstname ? (
                  <Cancel color="error" />
                ) : field.value ? (
                  <CheckCircle color="success" />
                ) : null,
              }}
            />
          </Tooltip>
        )}
      />

      {/* Email Address Field */}
      <Controller
        name="email"
        control={control}
        defaultValue=""
        rules={{
          required: "Email address is required",
          pattern: {
            value: EMAIL_REGEX,
            message: "Invalid email address.",
          },
        }}
        render={({ field }) => (
          <Tooltip title="Enter a valid email address." placement="top" arrow>
            <TextField
              {...field}
              label="Email Address"
              variant="outlined"
              fullWidth
              error={!!errors.email}
              helperText={errors.email ? errors.email.message : ""}
              InputProps={{
                endAdornment: errors.email ? (
                  <Cancel color="error" />
                ) : field.value ? (
                  <CheckCircle color="success" />
                ) : null,
              }}
            />
          </Tooltip>
        )}
      />

      {/* Password Field */}
      <Controller
        name="password"
        control={control}
        defaultValue=""
        rules={{
          required: "Password is required",
          pattern: {
            value: PWD_REGEX,
            message: "6-24 characters, must include uppercase, lowercase, and a number.",
          },
        }}
        render={({ field }) => (
          <Tooltip title="6-24 characters. Must include uppercase, lowercase, and a number." placement="top" arrow>
            <TextField
              {...field}
              label="Password"
              type="password"
              variant="outlined"
              fullWidth
              error={!!errors.password}
              helperText={errors.password ? errors.password.message : ""}
              InputProps={{
                endAdornment: errors.password ? (
                  <Cancel color="error" />
                ) : field.value ? (
                  <CheckCircle color="success" />
                ) : null,
              }}
            />
          </Tooltip>
        )}
      />

      {/* Confirm Password Field */}
      <Controller
        name="confirmPassword"
        control={control}
        defaultValue=""
        rules={{
          required: "Confirm password is required",
          validate: (value) => value === pwd || "Passwords must match",
        }}
        render={({ field }) => (
          <Tooltip title="Must match the password field." placement="top" arrow>
            <TextField
              {...field}
              label="Confirm Password"
              type="password"
              variant="outlined"
              fullWidth
              error={!!errors.confirmPassword}
              helperText={errors.confirmPassword ? errors.confirmPassword.message : ""}
              InputProps={{
                endAdornment: errors.confirmPassword ? (
                  <Cancel color="error" />
                ) : field.value ? (
                  <CheckCircle color="success" />
                ) : null,
              }}
            />
          </Tooltip>
        )}
      />

      {/* Submit Button */}
      <Button type="submit" variant="contained" color="primary" fullWidth disabled={!isValid}>
        Sign Up
      </Button>

      <Typography variant="body2" mt={2}>
        Already registered? <Link to="/register">Sign In</Link>
      </Typography>
    </Box>
  );
};

export default Register;
