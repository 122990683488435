import { useEffect, useState } from "react";
import { Typography, Box, Container } from "@mui/material";
import { List, ListItem, ListItemText } from "@mui/material";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useNavigate, useLocation } from "react-router-dom";

const HskList = () => {
  const [loading, setLoading] = useState(true);
  const [hskList, setHskList] = useState([]);
  const axiosPrivate = useAxiosPrivate();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();
    const getList = async () => {
      try {
        const response = await axiosPrivate.get("/getList", {
          signal: controller.signal,
        });
        console.log("response", response);
        if (isMounted) setHskList(response.data);
      } catch (error) {
        if (error.name === "CanceledError") {
          // Ignore canceled requests
          console.log("Request was canceled.");
          return;
        }
        if (error.response?.status === 401 || error.response?.status === 403) {
          // Redirect to login for unauthorized errors
          navigate("/login", { state: { from: location.pathname }, replace: true });
        } else {
          console.error("Error retrieving data:", error);
        }
        navigate("/login", { state: { from: location.pathname }, replace: true });
      } finally {
        if (isMounted) setLoading(false); // Ensure loading state is updated
      }
    };

    getList();

    return () => {
      isMounted = false; // Cleanup: Prevent state updates after unmount
      controller.abort(); // Cleanup
    };
  }, [axiosPrivate, navigate, location.pathname]);

  return (
    <Container maxWidth="md">
      <Box sx={{ textAlign: "center", mt: 5 }}>
        <Typography variant="h3" component="h1" gutterBottom>
          HSK List
        </Typography>
        {loading ? (
          <Typography variant="body1">Loading...</Typography>
        ) : (
          <List>
            {hskList.map((item) => (
              <ListItem key={item.hanzi_id}>
                <ListItemText primary={item.hanzi_id} secondary={item.hanzi_character} />
              </ListItem>
            ))}
          </List>
        )}
      </Box>
    </Container>
  );
};

export default HskList;
